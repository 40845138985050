// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import './assets/scss/app.scss'

ReactDOM.render(
        <App />,
    document.querySelector("#root")
)