import React from 'react'
import logo from '../assets/img/Logo.png'

class Layout extends React.Component{

    state = {
        open: false
    }

    onToggleMenu = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render(){
        return(
            <>
                <nav className={`_nav ${this.state.open ? '_show' : ''}`}>
                    <div>
                        <a href="/#" onClick={this.onToggleMenu}><img src={logo} alt="" /></a>
                    </div>
                    <ul>
                        <li>
                            <a href="/#about" onClick={this.onToggleMenu}>About Us</a>
                        </li>
                        <li>
                            <a href="/#menu" onClick={this.onToggleMenu}>Menu</a>
                        </li>
                        <li>
                            <a href="/#hey-gorgeous" onClick={this.onToggleMenu}>Hey Gorgeous</a>
                        </li>
                        <li>
                            <a href="/#contact-us" onClick={this.onToggleMenu}>Contact Us</a>
                        </li>
                    </ul>
                    <div>
                        <h3 className='reservation'>Reservation</h3>
                        <a href="tel:" className='contact_number'>081-016-8816</a>
                    </div>
                </nav>
                <div className='_hamburgerButton'>
                    <button onClick={this.onToggleMenu} className={`_hamburger-icon ${this.state.open ? '_close' : ''}`}>
                        <span />
                        <span />
                    </button>
                </div>
                {this.props.children}
            </>
        )
    }
}  

export default Layout