import React from 'react'
import Layout from './Layout'

//images
import bg1 from '../assets/img/Landing-Cover.jpg'
import bg2 from '../assets/img/Menu-Cover.jpg'
import bg3 from '../assets/img/Gallery-Cover.jpg'
import img1 from '../assets/img/01.jpg'
import img2 from '../assets/img/02.jpg'
import img3 from '../assets/img/03.jpg'
import img4 from '../assets/img/04.jpg'
import img5 from '../assets/img/05.jpg'
import img6 from '../assets/img/06.jpg'

import facebook from '../assets/img/InsignificonSW128-Facebook-i.png'
import instagram from '../assets/img/InsignificonSW128-Instagram-New.png'

import menu from '../assets/img/afcfb55e24ea47b287ef01bf4e07b55b-0002.jpg'



class Home extends React.Component{
    render(){
        return(
            <Layout>
                <div className='_page-content'>
                    <section className='_section-1' style={{backgroundImage: `url(${bg1})`}}>
                        <div className='_overlay2' />
                        <div className='_panel'>
                            <h1 className='_main-heading'>
                                <span>Brew.</span><br />
                                <span>Cape Town</span>
                            </h1>
                            <a href="/#menu" className='_button'>Our Menu</a>
                        </div>
                    </section>
                    <section className='_section-2' id="about">
                        <div className='_grid _grid-3-2'>
                            <div className='_column'>
                                <h2>About Us</h2>
                                <p>Welcome to Brew. Cape Town</p>
                                <p><i>Bru/bra/bro/boet– a close friend and a term of affection used by one (usually male) to another.</i></p>
                                <p><i>Brew - make (beer) by soaking, boiling, and fermentation or make (tea or coffee) by mixing with hot water.</i></p>
                                <p>We’re a bit of bru, a splash of brew and some serious “whoo-hoo!”</p>
                                <p>Come and hang out with us in the heart of the Mother City – in a space where everyone’s welcome, at a place you won’t want to leave.</p>
                                <p>Brew. Cape Town is a cool, curated hub where you can enjoy freshly made, wholesome food, perfectly poured brews (both the hot and cold type), a place to work (that isn’t your office or couch), let off steam with colleagues after work and hang out with friends over weekends. </p>
                                <p>Relax, unwind and enjoy great tunes, with live DJs on Friday, against the backdrop of our stylish, funky interior.</p>
                                <p>What can we say – it’s a vibe!</p>
                            </div>
                            <div className='_column'>
                                <img src={img1} alt="Bru" />
                                <img src={img2} alt="Bra" />
                            </div>
                        </div>
                    </section>
                    <section id="menu" className='_section-3' style={{backgroundImage: `url(${bg2})`}}>
                        <div className='_overlay' />
                        <div className='_grid'>
                            <div className='_column' style={{textAlign: 'center'}}>
                                <h2>Menu</h2>
                                <p>Order from the deli, restaurant or bar – to dine-in or take-away. Our menu is fresh, locally sourced, and full of all the good stuff.</p>
                                <p>The bar is always fully stocked with award-winning wines, the best beers, cocktails and local craft favourites.</p>
                                <p>Happy Hour is everyday between 16:00 – 18:00, with half price cocktails and draught beer.</p>
                            </div>
                        </div>
                        <div className='_grid _grid-2' style={{textAlign: 'left'}}>
                            <div className='_column'>
                                <h4>So Smooth Right Now</h4>

                                <p>Our Super Healthy (and ridiculously pretty) Smoothie Bowl packed with Mixed Berries, Acai, Yoghurt & House Made Granola - <span>R75</span></p> 
                                
                                <h4>Smashed Plain </h4>
                                
                                <p>Smashed Avo on Sourdough Toast with Crumbled Feta and Micro Greens - <span>R85</span></p> 
                                
                                <h4>Smashed Beetroot Hummus </h4>
                                
                                <p>Smashed Avo on Sourdough Toast with Beetroot Hummus, Crumbled Feta & Micro Greens - <span>R95</span><br />
                                Add - Free Range Poached Egg <span>R12</span> </p>
                                
                                <h4>Good Old Benny</h4>
                                
                                <p>Everyone’s Favorite Eggs Benny done our way . 2 Poached Eggs on sourdough topped with Hollandaise Sauce & your choice of: Spinach - <span>R95</span><br />
                                Bacon - R105 Smoked Salmon - <span>R115</span></p> 
                                
                                <h4>Big Bang</h4> 
                                
                                <p>2 Eggs Your Way, Crispy Bacon, Herb Roasted Mushrooms, Grilled Tomato, Beef or Pork Chipolatas, Hash Browns & Toast of your Choice. - <span>R115</span></p>
                            </div>
                            <div className='_column'>
                                <h4>Babalas Burger</h4> 
                                
                                <p>Killer Breakfast Burger with Fried Egg, Crispy Bacon, Cheddar Cheese, Hash Brown & Onion Rings. Served with Fries. - <span>R85</span></p> 
                                
                                <h4>Let’s Get Baked </h4>
                                
                                <p>Eggs Baked in a Spiced Shakshukah Sauce with Grilled Halloumi Cheese & Spinach - <span>R95</span></p> 
                                
                                <h4>Not So Average Joe</h4> 
                                
                                <p>Our Signature Sloppy Joe’s. Thick Cut Farmhouse Bread Smothered in Delicious Beef Mince Sauce, Topped with a Fried Egg - <span>R95</span><br />
                                You’re The Boss</p> 
                                
                                <h4>DIY Breakfast.</h4>
                                
                                <p>Eggs your way with 2 Slices of Toast - <span>R35</span><br />
                                Add your own sides -</p> 
                                
                                <p>Bacon <span>R25</span> <br />
                                Pork Chipolatas <span>R20</span> <br />
                                Beef Chipolatas <span>R20</span> <br />
                                Hash Brown <span>R15</span> <br />
                                Avo (seasonal) <span>R20</span> <br />
                                Sautéed Mushrooms <span>R15</span> <br />
                                Grilled Rosa Tomatoes <span>R15</span> <br />
                                Toast <span>R15</span></p>
                            </div>
                        </div>
                        <div className='_grid'>
                            <div className='_column' style={{textAlign: 'center', marginTop: '30px'}}>
                                <a href={menu} download="Brew Cape Town Menu" className='_button'>Full Menu</a>
                            </div>
                        </div>
                    </section>
                    <section className='_section-4' id="hey-gorgeous">
                        <div className='_grid'>
                            <div className='_column'>
                                <h2>Hey Gorgeous</h2>
                                <p>Hey Gorgeous. Yes, you!</p>
                                <p>Every Friday Brew. hosts Hey Gorgeous Fridays with top live DJs, a complimentary welcome drink and half price cocktails and draughts between 17:00 – 19:00.</p>
                                <p>It’s the best place, in the heart of Cape Town, for drinks after work or to kick start your weekend in style.</p>
                                <p>Watch our social media feed to find out about all the latest specials and who’ll be spinning the decks each week.</p>
                            </div>
                        </div>
                        <div className='_grid _grid-3'>
                            <div className='_column'>
                                <img src={img1} alt="" />
                            </div>
                            <div className='_column'>
                                <img src={img2} alt="" />
                            </div>
                            <div className='_column'>
                                <img src={img3} alt="" />
                            </div>
                            <div className='_column'>
                                <img src={img4} alt="" />
                            </div>
                            <div className='_column'>
                                <img src={img5} alt="" />
                            </div>
                            <div className='_column'>
                                <img src={img6} alt="" />
                            </div>
                        </div>
                    </section>
                    <footer style={{backgroundImage: `url(${bg3})`}} id="contact-us">
                        <div className='_overlay' />
                        <div className='_grid'>
                            <div className='_column'>
                                <h2>Contact Us</h2>
                                <p>Don’t be shy! Whether you have a question about a private function (baby shower, bachelorette or bar mitzvah), or a dietary requirement query – we’re always happy to help.</p>
                            </div>
                        </div>
                        <div className='_grid _grid-3'>
                            <div className='_column'>
                                <h3>Where we’re at</h3>
                                <p className='_pink'>
                                    <a href="https://goo.gl/maps/w8bv3E4jvjrN7Pdp8" target="_blank" without rel="noreferrer">
                                        Rockwell Luxury Suites<br />
                                        Preswich Street<br />
                                        Cape Town, South Africa<br />
                                    </a>
                                </p>
                            </div>
                            <div className='_column'>
                                <h3>Ping us on</h3>
                                <p className='_pink'>
                                    <a href="tel:0810168816">081 016 8816</a><br />
                                    <a href="mailto:michelle@brewct.co.za">michelle@brewct.co.za</a><br />
                                </p>
                            </div>
                            <div className='_column'>
                                <h3>We’re open</h3>
                                <p className='_pink'>Monday – Thursday 7:30 – 21:00<br />
                                Friday & Saturday 7:30 – 22:00 <br />
                                Sunday 7:30 – 20:00<br />
                                *Note: last food orders 20:00 (unless otherwise arranged)</p>
                            </div>
                        </div>
                        <div className='_grid'>
                            <div className='_column'>
                                <div className='_bottom-footer-panel'>
                                    <div className='_panel-grid'>
                                        <a href="https://www.facebook.com/brewcpt" target="_blank" rel="noreferrer">
                                            <img src={facebook} alt="f" width="36" />
                                        </a>
                                        <a href="https://www.instagram.com/brewcpt/" target="_blank" rel="noreferrer">
                                            <img src={instagram} alt="i" width="36" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </Layout>
        )
    }
}  

export default Home