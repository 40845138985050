import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom'

import Home from './Home'

class App extends React.Component{
    render(){
        return(
            <Router>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home />} />
                    </Route>
                </Routes>
            </Router>
        )
    }
}  

export default App